import React from "react";
import { graphql } from "gatsby";
import "@fontsource/red-hat-display";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";

import logo from "/src/images/iso_color.png";

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined";

const Warranty = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  let header;
  header = <img style={{ width: "50px" }} src={logo} alt="Logo" />;
  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  if (isBrowser) {
    window.addEventListener("scroll", reveal);
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="MicroREC App Privacy Policy | Custom Surgical" />
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "1200px",
          paddingTop: "4em",
          marginBottom: "2em",
        }}
      >
        <h1 style={{ marginTop: "0" }}>MicroREC App Privacy Policy</h1>
        <div className="container3" id="footer_page_flex">
          <div id="footer_page_width1">
            <h2 style={{ marginTop: "0" }}>Subject of this Privacy Policy</h2>
          </div>
          <div id="footer_page_width2">
            <p>
              Thank you for choosing to be part of our community at Custom
              Surgical GmbH ("Company", "we", "us", "our"). We are committed to
              protecting your personal information and your right to privacy. If
              you have any questions or concerns about this privacy notice or
              our practices with regards to your personal information, please
              contact us at{" "}
              <a
                href="mailto:support@customsurgical.de "
                className="links_post"
              >
                support@customsurgical.de.{" "}
              </a>
            </p>
            <p>
              Custom Surgical GmbH built the MicroREC app. This SERVICE is
              provided by Custom Surgical GmbH and is intended for use as is.
            </p>
            <p>
              In this privacy notice, we seek to explain to you in the clearest
              way possible what information we collect, how we use it, and what
              rights you have in relation to it. We hope you take some time to
              read through it carefully, as it is important. If there are any
              terms in this privacy notice that you do not agree with, please
              discontinue use of our Services immediately.
            </p>
            <p>
              If you choose to use our Service, then you agree to the collection
              and use of information in relation to this policy. The Personal
              Information that we collect is used for providing and improving
              the Service. We will not use or share your information with anyone
              except as described in this Privacy Policy.
            </p>
            <p className="container3 reveal fade-left">
              The terms used in this Privacy Policy have the same meanings as in
              our Terms and Conditions, which is accessible at MicroREC unless
              otherwise defined in this Privacy Policy.
            </p>
            <p className="container3 reveal fade-left">
              <b>
                Please read this privacy notice carefully as it will help you
                understand what we do with the information that we collect.{" "}
              </b>
            </p>
            <h2 className="container3 reveal fade-right">
              Data Controller or Business Associate
            </h2>
            <p className="container3 reveal fade-left">
              The Data Controller is Custom Surgical GmbH, based in
              Agnes-Pockels-Bogen 1, 80992 Munich. Germany. You can find further
              information in our{" "}
              <a className="links_post" href="/impressum">
                imprint
              </a>
              .
            </p>
            <h2 className="container3 reveal fade-right">
              What information we collect{" "}
            </h2>
            <p className="container3 reveal fade-left">
              We collect three categories of information: Information you
              provide, automatically collected information and information from
              other sources. More detail about each of the categories is
              provided below.
            </p>
            <p className="container3 reveal fade-left">
              <b>Information you provide</b>
            </p>
            <p className="container3 reveal fade-left">
              &#8226; <b>Profile information:</b> When you create an account,
              you provide us with personal information that includes your full
              name, email address, country of residence, profession and a
              password.
            </p>
            <p className="container3 reveal fade-left">
              &#8226; <b>User content:</b> We collect the content you create
              through the app, including photographs, videos, audio recordings,
              patient IDs, session labels, session descriptions, session dates
              and session locations.
            </p>
            <p className="container3 reveal fade-left">
              &#8226; <b>Information when you contact us:</b> If you contact us,
              we collect the information you give us during the interaction.
            </p>
            <h3 className="container3 reveal fade-right">
              Automatically collected information
            </h3>
            <p className="container3 reveal fade-left">
              &#8226; <b>Technical information:</b> We collect certain device
              and network connection information when you access any of our
              Services. This information includes your device model, operating
              system version, IP address, the configuration of the app and the
              time and date of your use of the Service. We also collect
              service-related, diagnostic, and performance information,
              including crash reports and performance logs. We automatically
              assign you a device ID and user ID when you use our app. Where you
              log-in from multiple devices, we use information such as your
              device ID and user ID to identify your activity across devices to
              give you a seamless log-in experience and for security purposes.
            </p>
            <h3 className="container3 reveal fade-right">
              Information from other sources{" "}
            </h3>
            <p className="container3 reveal fade-left">
              &#8226; <b>Third party platform information:</b> If you choose to
              sign-up using a third party platform (such as Google), the third
              party platform will share information such as your email address,
              user ID and public profile.
            </p>
            <h2 className="container3 reveal fade-right">
              Authentication via Firebase
            </h2>
            <h3 className="container3 reveal fade-right">
              Using Firebase Authentication{" "}
            </h3>
            <p className="container3 reveal fade-left">
              In our apps we use Google Firebase Authentication, a service of
              Google Ireland Limited, Gordon House, 4 Barrow Street, D04 E5W5
              Dublin, Ireland (“Google”) and Google Cloud at 1600 Amphitheatre
              Pkwy, Mountain View, California, United States in USA. Firebase
              Authentication is a login and authentication service provided by
              Google. To simplify the sign-in and authentication process,
              Firebase Authentication can use third-party identity services and
              store the information on its platform. With respect to the
              possible collection of data by third parties, we point to the
              following points, where the respective data collected is listed.
              Firebase Authentication thus acts as an intermediary for data
              collection, third parties and us. Firebase Authentication stores
              the data collected on our behalf so that we can use and process it
              for the Apps. We comply with the strict legal requirements for
              such a contract with Firebase Authentication with regard to the
              contract processing as defined in Art. 28 GDPR.
            </p>
            <p className="container3 reveal fade-left">
              This concerns the following personal data collected: Username,
              profile picture and e-mail address. When registering via SMS
              identification, the corresponding mobile number is also collected.
            </p>
            <p className="container3 reveal fade-left">
              In these processing cases, the processing takes place on the basis
              of your consent pursuant to Art. 6 (1)(a) in conjunction with Art.
              7 GDPR. Processing therefore takes place on a legal basis.
            </p>
            <p className="container3 reveal fade-left">
              Further information on data protection can be found in Google’s
              data protection conditions{" "}
              <a
                className="links_post"
                href="https://policies.google.com/privacy "
              >
                https://policies.google.com/privacy.
              </a>
            </p>
            <h3 className="container3 reveal fade-right">
              Use of Facebook Connect
            </h3>
            <p className="container3 reveal fade-left">
              Our apps allow you to login through Facebook-Connect. Facebook
              Connect is a service provided by Facebook 1 Hacker Way Menlo Park,
              CA 94025 USA (“Facebook”). This service makes an additional
              separate registration with your personal data mandatory. For
              registration purposes, you will either switch to the locally
              installed Facebook app or be redirected to an appropriate Facebook
              website. There you can log in with your user data and consent to
              the processing of the following data by us. This links your
              Facebook profile to our platform. Through this link, Facebook
              provides us with the following information about your public
              profile: User ID, first name, last name, e-mail address, gender,
              language, country, age range, friends, likes, profile picture and
              relationship status. We will only use the following information:
              Your user ID, first name, last name, e-mail address . This
              information is required for registration – it is necessary for
              identification purposes. The processing takes place on the basis
              of your consent expressly given during registration in accordance
              with Art. 6 (1)(a) GDPR. You may revoke this consent at any time
              in accordance with Art. 7 (3) GDPR.
            </p>
            <p className="container3 reveal fade-left">
              Facebook’s privacy policy is available here:
              <a
                className="links_post"
                href="https://www.facebook.com/about/privacy/"
              >
                https://www.facebook.com/about/privacy/
              </a>
              .
            </p>
            <h3 className="container3 reveal fade-right">
              Use of Google Login
            </h3>
            <p className="container3 reveal fade-left">
              Our apps allow you to log in through the Google login, a link to
              the service provider Google Ireland Limited, Gordon House, 4
              Barrow Street, D04 E5W5 Dublin, Ireland (“Google”). This service
              makes an additional separate registration with your personal data
              mandatory. You will be redirected to a Google page for
              registration purposes. There you can log in with your user data
              and consent to the processing of the following data by us. This
              links your Google profile to our apps. Through this link we
              receive the following information from Google: User ID, first
              name, surname, username, e-mail address and profile picture. We
              only use the following data: Your user ID, first name, last name,
              e-mail address. This information is mandatory for registration –
              it is necessary for identification. Processing takes place on the
              basis of your express consent given during registration in
              accordance with Art. 6 (1)(a) GDPR. You may revoke this consent at
              any time in accordance with Art. 7 (3) GDPR.
            </p>
            <p className="container3 reveal fade-left">
              Google’s privacy policy is available here:{" "}
              <a
                className="links_post"
                href="https://www.google.com/policies/privacy/ "
              >
                https://www.google.com/policies/privacy/
              </a>
              .
            </p>
            <h3 className="container3 reveal fade-right">Use of Apple Login</h3>
            <p className="container3 reveal fade-left">
              You can also register and sign in through your Apple account. If
              you sign in with Apple, Apple will ask you for a confirmation to
              use your apple account to sign in to MRS. Sign in with Apple
              protects your privacy by allowing you to sign in to our website
              and apps without having to provide us with information that
              personally identifies you except information used by your browser
              for normal web functions. We may ask for your name and email
              address when you use Sign in with Apple. Your name will default to
              the name associated with your Apple ID and for the email address,
              you can choose to provide us with any of the email addresses
              associated with your Apple ID, or to hide your email address. If
              you choose to hide your email address from us and also disable
              email forwarding from the Apple generated email address to your
              personal email address, please understand that we will not be able
              to reach out to you with our communication messages. The legal
              basis for this is Art. 6 (1)(b) and (f) GDPR.
            </p>
            <p className="container3 reveal fade-left">
              Apple's privacy policy is available here:
              <a
                className="links_post"
                href="https://www.apple.com/legal/privacy/data/en/sign-in-with-apple/ "
              >
                https://www.apple.com/legal/privacy/data/en/sign-in-with-apple/
              </a>
              .
            </p>
            <h2 className="container3 reveal fade-right">
              How we use your information and our legal bases
            </h2>
            <p className="container3 reveal fade-left">
              We use your information to operate, provide, develop and improve
              our services, including for the following purposes.{" "}
            </p>
            <h3 className="container3 reveal fade-right">
              A. Administer your account and provide our services to you{" "}
            </h3>
            <p className="container3 reveal fade-left">
              &#8226; Create and manage your account{" "}
            </p>
            <p className="container3 reveal fade-left">
              &#8226; Enable you to use the app and to provide you with its
              functionalities{" "}
            </p>
            <p className="container3 reveal fade-left">
              &#8226; Provide you with customer support and respond to your
              requests{" "}
            </p>
            <p className="container3 reveal fade-left">
              &#8226; Communicate with you about our services{" "}
            </p>
            <h3 className="container3 reveal fade-right">
              B. Improve our services and develop new ones{" "}
            </h3>
            <p className="container3 reveal fade-left">
              &#8226; Understand how members typically use the services to
              improve them{" "}
            </p>
            <p className="container3 reveal fade-left">
              &#8226; Ensure the quality of the services by analyzing,
              preventing or correcting failures and bugs, illicit use or misuse
              of the services
            </p>
            <p className="container3 reveal fade-left">
              &#8226; Develop new features and services{" "}
            </p>
            <h3 className="container3 reveal fade-right">
              C. Ensure legal compliance
            </h3>
            <p className="container3 reveal fade-left">
              &#8226; Comply with legal requirements
            </p>
            <p className="container3 reveal fade-left">
              &#8226; Assist law enforcement{" "}
            </p>
            <h3 className="container3 reveal fade-right">
              D. Marketing communications{" "}
            </h3>
            <p className="container3 reveal fade-left">
              &#8226; Send you information and marketing communications about
              our products and services{" "}
            </p>
            <p className="container3 reveal fade-left">
              To process your information as described in this Privacy Policy,
              we rely on the following legal bases:{" "}
            </p>
            <p className="container3 reveal fade-left">
              <i>Provide our service to you:</i> The reason we process your
              information for purpose A above is to perform the contract that
              you have with us (Art. 6 (1)(b) of the GDPR).{" "}
            </p>
            <p className="container3 reveal fade-left">
              <i>Legitimate interests:</i> We process your information for
              purpose B above based on our legitimate interest (Art. 6 (1)(f) of
              the GDPR). For instance, we use the information we have to
              understand how people are using our services to improve and
              develop our services and we use your information to carry out
              testing and analysis to ensure the stability and security of our
              services.{" "}
            </p>
            <p className="container3 reveal fade-left">
              <i>Comply with applicable laws and regulations: </i>We process
              your information for purpose C above where it is necessary for us
              to comply with applicable laws and regulations and evidence our
              compliance with applicable laws and regulations (Art. 6 (1)(c) of
              the GDPR).{" "}
            </p>
            <p className="container3 reveal fade-left">
              <i>Consent:</i> We ask for your consent to access or use your
              information for specific purposes (Art. 6 (1)(a) of the GDPR). If
              we do, you'll always be able to revoke your consent through the
              in-app settings or by contacting us at{" "}
              <a
                className="links_post"
                href="mailto:support@customsurgical.de "
              >
                support@customsurgical.de
              </a>
              .
            </p>
            <h2 className="container3 reveal fade-right">
              How we share your information
            </h2>
            <h3 className="container3 reveal fade-right">
              With our service providers{" "}
            </h3>
            <p className="container3 reveal fade-left">
              We use third parties to help us operate and improve our services.
              These third parties assist us with various tasks, including data
              hosting and maintenance, analytics and marketing. We share your
              personal information with these service providers as necessary to
              enable them to provide their services.{" "}
            </p>
            <h3 className="container3 reveal fade-right">
              With third party platforms{" "}
            </h3>
            <p className="container3 reveal fade-left">
              We partner with third party platforms (such as Facebook or Google)
              to offer you a seamless log-in and content sharing experience. We
              share information with these third party platforms when you want
              to use these features. For example, if you choose to:
            </p>
            <p className="container3 reveal fade-left">
              &#8226; sign-up or log-in to the Platform using your account
              details from a third party platform, we will share certain
              technical information to facilitate this;{" "}
            </p>
            <p className="container3 reveal fade-left">
              &#8226; share user content on social media platforms, we will
              share your user content and related information.{" "}
            </p>
            <p className="container3 reveal fade-left">
              &#8226; become a Premium-member, your content will be
              automatically uploaded to and stored in Google Cloud. When using
              the Google Cloud feature, Google’s privacy policy applies to
              accessing to Google Cloud. You can view the Google privacy policy
              here:
              <a
                href="https://policies.google.com/privacy?hl=en-US"
                target="_blank"
                className="links_post"
              >
                https://policies.google.com/privacy?hl=en-US
              </a>
            </p>
            <h3 className="container3 reveal fade-right">
              With law enforcement / when required by law{" "}
            </h3>
            <p className="container3 reveal fade-left">
              We may disclose your information if reasonably necessary: (i) to
              comply with a legal process, such as a court order, subpoena or
              search warrant, government / law enforcement investigation or
              other legal requirements; (ii) to assist in the prevention or
              detection of crime (subject in each case to applicable law); or
              (iii) to protect the safety of any person.{" "}
            </p>
            <h3 className="container3 reveal fade-right">
              To enforce legal rights{" "}
            </h3>
            <p className="container3 reveal fade-left">
              We may also share information: (i) if disclosure would mitigate
              our liability in an actual or threatened lawsuit; (ii) as
              necessary to protect our legal rights and legal rights of our
              members, business partners or other interested parties; (iii) to
              enforce our agreements with you; and (iv) to investigate, prevent,
              or take other action regarding illegal activity, suspected fraud
              or other wrongdoing.
            </p>
            <h2 className="container3 reveal fade-right">
              Transfers of Personal Data Outside the European Economic Area
            </h2>
            <p className="container3 reveal fade-left">
              We may transfer personal data from the European Economic Area
              (“EEA”), the UK or Switzerland to other countries outside the EEA.
              Such data transfers are based on appropriate safeguards in
              accordance with Applicable Privacy Laws, including (a) the
              standard contractual clauses developed by the European Commission;
              (b) the decisions of adequacy of the European Commission; or (c)
              binding corporate rules.
            </p>
            <h2 className="container3 reveal fade-right">
              How long we retain your information{" "}
            </h2>
            <p className="container3 reveal fade-left">
              As a matter of principle, we only store your data for as long as
              we need it to ensure that you can use our products. This is
              equivalent to the period for which you have a valid user account
              with us. Beyond that, data is only stored if we are legally
              obligated to do so (e.g. storage and documentation obligations).
            </p>
            <h2 className="container3 reveal fade-right">Security</h2>
            <p className="container3 reveal fade-left">
              We value your trust in providing us your Personal Information,
              thus we are striving to use commercially acceptable means of
              protecting it. But remember that no method of transmission over
              the internet, or method of electronic storage is 100% secure and
              reliable, and we cannot guarantee its absolute security.
            </p>
            <h2 className="container3 reveal fade-right">Your rights</h2>
            <p>
              You have rights and choices when it comes to your information.
              Some of these rights apply generally, while others will only apply
              in certain circumstances. Depending on the scenario, these rights
              may be subject to some limitations.{" "}
            </p>
            <p className="container3 reveal fade-left">
              <b>Access your information:</b> You can ask us, free of charge, to
              confirm what information we process about you, to provide certain
              information about the processing and for a copy of your
              information.{" "}
            </p>
            <p className="container3 reveal fade-left">
              <b>Updating your information: </b>You can change or ask us to
              change or correct your information where that information is not
              accurate. You can change or correct some of your information
              through the in-app controls and settings.{" "}
            </p>
            <p className="container3 reveal fade-left">
              <b>Delete your information:</b> You can delete or ask us to delete
              some or all of your information.
            </p>
            <p className="container3 reveal fade-left">
              <b>Restrict the processing of your information:</b> You have the
              right to request the restriction of the processing of your
              information where (a) you are challenging the accuracy of the
              information, (b) the information has been unlawfully processed,
              but you are opposing the deletion of that information, (c) where
              you need the information to be retained for the pursuit or defense
              of a legal claim, or (d) you have objected to processing and you
              are awaiting the outcome of that objection request.{" "}
            </p>
            <p className="container3 reveal fade-left">
              <b>Port your information:</b> You have the right to data
              portability. This means that you have the right to receive your
              information in a structured, commonly used, and machine-readable
              format and to share it with a third party.{" "}
            </p>
            <p className="container3 reveal fade-left">
              <b>Object to the processing of your information: </b>You also have
              the right to object to the processing of your information in
              certain circumstances. The right to object applies when the
              processing you are objecting to is relying on legitimate interest
              as a legal basis. In submitting an objection request, you should
              explain what specific processing activity you are objecting to and
              why the processing should be stopped. We will stop the particular
              processing if we don't have compelling legitimate grounds to
              continue that processing or don’t need it for legal claims.{" "}
            </p>
            <p className="container3 reveal fade-left">
              <b>Withdraw your consent:</b> Where we rely on your consent to
              process your personal data, you have the right to withdraw your
              consent, although the processing carried out before your
              withdrawal of consent will remain valid.{" "}
            </p>
            <p className="container3 reveal fade-left">
              <b>Exercising your rights:</b> To exercise any of the above
              rights, please contact us at{" "}
              <a
                href="mailto:support@customsurgical.de "
                className="links_post"
              >
                support@customsurgical.de
              </a>
              . Before we can respond to a request to exercise one or more of
              the rights listed above, you may be required to verify your
              identity or your account details or provide additional information
              so that we can understand the issue.{" "}
            </p>
            <p className="container3 reveal fade-left">
              You also have the right to lodge a complaint with the appropriate
              data protection authority if you have concerns about how we
              process your personal information.{" "}
            </p>
            <h2 className="container3 reveal fade-right">
              Links to other sites
            </h2>
            <p className="container3 reveal fade-left">
              This Service may contain links to other sites. If you click on a
              third-party link, you will be directed to that site. Note that
              these external sites are not operated by us. Therefore, we
              strongly advise you to review the Privacy Policy of these
              websites. We have no control over and assume no responsibility for
              the content, privacy policies, or practices of any third-party
              sites or services.
            </p>
            <h2 className="container3 reveal fade-right">
              Changes to this Privacy Policy
            </h2>
            <p className="container3 reveal fade-left">
              We may update our Privacy Policy from time to time. Thus, you are
              advised to review this page periodically for any changes. We will
              notify you of any changes by posting the new Privacy Policy on
              this page.{" "}
            </p>
            <p className="container3 reveal fade-left">
              <b>This policy is effective as of 2023-05-09</b>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Warranty;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
